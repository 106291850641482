import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-snowflake',
  templateUrl: './snowflake.component.html',
  styleUrls: ['./snowflake.component.scss']
})
export class SnowflakeComponent implements OnInit {
  private snowflakeImages = [
    '../../../../assets/images/hob/snowflakes1.png',
    '../../../../assets/images/hob/snowflakes2.png',
    '../../../../assets/images/hob/snowflakes3.png',
    '../../../../assets/images/hob/snowflakes4.png',
    '../../../../assets/images/hob/snowflakes5.png'
  ];

  private numberOfSnowflakes = 30; // Adjust the number of snowflakes
  private animationDurationRange = [15, 25]; // Adjust the duration range (in seconds)

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.createSnowflakes();
  }

  createSnowflakes(): void {
    const container = this.renderer.selectRootElement('#snowflake-container');

    for (let i = 0; i < this.numberOfSnowflakes; i++) {
      const snowflake = this.renderer.createElement('img');

      // Set random snowflake image
      const randomImage =
        this.snowflakeImages[
          Math.floor(Math.random() * this.snowflakeImages.length)
        ];
      this.renderer.setAttribute(snowflake, 'src', randomImage);

      // Set random position and size
      const randomLeft = Math.random() * 100; // Percentage of viewport width
      const randomSize = Math.random() * 20 + 10; // 10px to 30px size

      // Set random animation duration
      const randomDuration =
        Math.random() * (this.animationDurationRange[1] - this.animationDurationRange[0]) +
        this.animationDurationRange[0];

      // Apply styles
      this.renderer.setStyle(snowflake, 'position', 'absolute');
      this.renderer.setStyle(snowflake, 'left', `${randomLeft}vw`);
      this.renderer.setStyle(snowflake, 'width', `${randomSize}px`);
      this.renderer.setStyle(snowflake, 'height', `${randomSize}px`);
      this.renderer.setStyle(snowflake, 'animation', `fall ${randomDuration}s linear infinite, sway ${randomDuration / 2}s ease-in-out infinite`);
      this.renderer.setStyle(snowflake, 'opacity', '0.8');

      this.renderer.appendChild(container, snowflake);
    }
  }
}
