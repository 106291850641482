import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import {AuthGuard} from './Services/auth_guard/auth_guard.service';
import {CartGuard} from './Services/auth_guard/protect_cart.service';

import { DatePipe } from '@angular/common';
import { HeaderModule } from './Components/Common/header/Header/header.module';
import { HeaderTwoModule } from './Components/Common/header/header-two/header-two.module';
import { FooterLuckMeModule } from './Components/Common/footer/footer-luckme/footer-luckme.module';
import { FooterModule } from './Components/Common/footer/footer/footer.module';
import { FooterTwoModule } from './Components/Common/footer/footer-two/footer-two.module';
import { LoaderModule } from './Components/loader/loader.module';
import { BannerTwoComponent } from './Components/banner-two/banner-two.component';
import { LinkComponent } from './link/link.component';
import { SnowflakeComponent } from './Components/Common/snowflake/snowflake.component';

// import { TimePickerComponent } from './Lazy-loaded-Modules/time-picker/time-picker.component';
// import { MyaccoutKatbookComponent } from './Lazy-loaded-Modules/Account-Modules/myaccout-katbook/myaccout-katbook.component';
// import { SignUpComponent } from './Lazy-loaded-Modules/Account-Modules/sign-up/sign-up.component';
// import { FeedbackFormComponent } from './Lazy-loaded-Modules/feedback-form/feedback-form.component';



@NgModule({
  declarations: [
    AppComponent,
    LinkComponent,
    SnowflakeComponent,
   
    // SignUpComponent,
    // FeedbackFormComponent
  
  ],
  imports: [

    // Global Modules
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    Ng5SliderModule,
    // Header and footer modules
    LoaderModule,
    HeaderModule,
    HeaderTwoModule,
    FooterLuckMeModule,
    FooterModule,
    FooterTwoModule,
    FormsModule
    
  ],
  providers: [AuthGuard,CartGuard,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
